import { useModal } from "../../../../utils/ModalContext";
import Counter from "../../../../common/counter";
import Button from "../../../../common/button";
import BannerV1Wrapper from "./Banner.style";

import characterThumb from "../../../../assets/images/nft/Character1.jpeg";
// import mintLiveDownArrow from "../../../../assets/images/nft/mint_live_down_arrow.svg";
// import mintLiveText from "../../../../assets/images/nft/mint_live_text.png";
import homeImageBG from "../../../../assets/images/nft/home_img_bg.png";
import {ethers} from "ethers";
import catShibApi from "../../../../assets/json/CatShib.json";
import {formatUnits} from "ethers/lib/utils";
import {useEffect, useState} from "react";

const Banner = () => {
  const ethereum = typeof window !== 'undefined' ? window.ethereum : '';

  const [maxSupply, setMaxSupply] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);

  const { mintModalHandle } = useModal();


  const getMaxSupply = async () => {
    if (typeof ethereum === 'undefined') return;
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const contractAddress = "0x5Cf6e17255B3908D9e3B3dc527a18c80179fc871";
    const contract = new ethers.Contract(contractAddress, catShibApi, provider);
    const maxSupply = await contract.MAX_SUPPLY();
    setMaxSupply(Number(formatUnits(maxSupply, 0)))
  }

  const getTotalSupply = async () => {
    if (typeof ethereum === 'undefined') return;
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const contractAddress = "0x5Cf6e17255B3908D9e3B3dc527a18c80179fc871";
    const contract = new ethers.Contract(contractAddress, catShibApi, provider);
    const totalSupply = await contract.totalSupply();
    const formatTotalSupply = Number(formatUnits(totalSupply, 0));
    setTotalSupply(formatTotalSupply);
  }

  useEffect(() => {
    getMaxSupply().then();
    getTotalSupply().then();
  }, [])


  return (
    <BannerV1Wrapper id="home">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="catshib_v1_baner_left">
              <h3>CatShib on Shibarium🎯 NFT collections</h3>
              <h3>
                <span className="count">
                  <Counter start={totalSupply} duration={maxSupply} />
                </span>{" "}
                / {maxSupply} Minted
              </h3>
              <div className="banner_buttons">
                <Button  lg variant="outline" onClick={() => mintModalHandle()}>
                  {" "}
                  Mint now
                </Button>
                <Button lg variant="mint">
                  WhileList Now
                </Button>
              </div>
              <div className="coin-info">
                <span>Max 5 NFTs per wallet , Price 0 Million SHIB</span>
                <span>
                  MINT IS LIVE{" "}
                  <span className="highlighted">UNTIL 2023 APR 25  04:00 PM UTC</span>
                </span>
                <span>Status : Soon</span>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="catshib_v1_baner_right">
              <div className="catshib_v1_baner_right_img_sect">
                {/* <div className="mint_live_circle_sect">
                  <div className="mint_live_circle">
                    <span>
                      <img src={mintLiveDownArrow} alt="" />
                    </span>
                    <span className="mint_live_text rotated-style">
                      <img src={mintLiveText} alt="" />
                    </span>
                  </div>
                </div> */}
                {/* <div className="catshib_v1_baner_right_img_bg">
                  <img src={homeImageBG} alt="" />
                </div> */}
                <div className="catshib_v1_baner_right_img">
                  <img src={characterThumb} alt="avater" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BannerV1Wrapper>
  );
};

export default Banner;
