import aboutCardIcon1 from "../../images/icon/aboutus_card_icon1.svg"
import aboutCardIcon2 from "../../images/icon/aboutus_card_icon2.svg"
import aboutCardIcon3 from "../../images/icon/aboutus_card_icon3.svg"

 const data = {
    aboutDescription1: 'Cat NFT is a collection of adorable and unique cat-themed non-fungible tokens (NFTs) built on the Shibarium platform. Each NFT in the collection is carefully designed and crafted to represent a different type of cat, making them a delightful addition to any NFT collection.',
    aboutDescription2: 'With their charming designs and accessible trading capabilities, Cat NFTs are an excellent way to own a piece of digital art and add value to any NFT portfolio. Whether you are a cat lover or a collector looking for unique NFTs, Cat NFTs on the Shibarium platform offer an excellent investment opportunity.',
    aboutInfo: [
        {
            icon: aboutCardIcon1,
            title: "Gamming NFTs",
            text: "Cat Combat is a strategic game featuring cute warrior cats battling against other players. With customizable equipment and rare rewards, it's an engaging and social experience that uses Cat NFT. Popular among gaming and NFT enthusiasts."
        },
        {
            icon: aboutCardIcon2,
            title: "Stake to Earn",
            text: 'Cat NFT is a stake-to-earn platform where users collect and stake unique cat-themed NFTs to earn CATShib tokens. The platform offers a range of adorable NFTs and a simple staking process. Cat NFT is a fun and rewarding way to own digital art and earn real-world value.'
        },
        {
            icon: aboutCardIcon3,
            title: "Metaverse Ready",
            text: 'Cat NFT offers a collection of unique cat-themed NFTs for use in the metaverse. With charming designs and compatibility with various platforms, they are a valuable addition to any NFT collection. Explore the Cat NFT Metaverse today and own adorable cat NFTs.'
        }
    ]
}

export default data;