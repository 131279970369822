// import { useState } from "react";
import {useModal} from "../../../utils/ModalContext";
import {FiX} from "react-icons/fi";
import Button from "../../button";
import MintModalStyleWrapper from "./MintNow.style";
import mintImg from "../../../assets/images/icon/mint-img.png";
import hoverShape from "../../../assets/images/icon/hov_shape_L.svg";
import {useEffect, useState} from "react";
import {ethers} from "ethers";
import catShibApi from "../../../assets/json/CatShib.json"
import {formatEther, formatUnits} from "ethers/lib/utils";

const MintNowModal = () => {
    const ethereum = typeof window !== 'undefined' ? window.ethereum : '';

    const [count, setCount] = useState(0);
    const [maxSupply, setMaxSupply] = useState(0);
    const [totalSupply, setTotalSupply] = useState(0);
    const [currentFeeMint, setCurrentFeeMint] = useState(0);
    const [totalNFT, setTotalNFT] = useState(0);

    const {mintModalHandle} = useModal();

    useEffect(() => {
        getData().then();
    }, [])

    const getData = async () => {
        await getTotalSupply();
        await getMaxSupply();
        await getBalance();
    }

    const getBalance = async () => {
        if (typeof ethereum === 'undefined') return;
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()
        const contractAddress = "0x5Cf6e17255B3908D9e3B3dc527a18c80179fc871";
        const contract = new ethers.Contract(contractAddress, catShibApi, signer);
        const balance = await contract.balanceOf(signer.getAddress());
        const formatBalance = Number(formatUnits(balance, 0));
        setTotalNFT(formatBalance)
    }

    const getFeeMint = async (totalSupply) => {
        if (typeof ethereum === 'undefined') return;
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const contractAddress = "0x5Cf6e17255B3908D9e3B3dc527a18c80179fc871";
        const contract = new ethers.Contract(contractAddress, catShibApi, provider);
        let feeMint;
        if (totalSupply <= 10000) {
            feeMint = await contract.FEE_MINT_UNDER_10000();
        }
        if (totalSupply > 10000 && totalSupply <= 30000) {
            feeMint = await contract.FEE_MINT_OVER_30000();
        }
        if (totalSupply > 30000 && totalSupply <= 50000) {
            feeMint = await contract.FEE_MINT_UNDER_30000();
        }
        if (feeMint) {
            setCurrentFeeMint(Number(formatEther(feeMint)))
        }
    }

    const getMaxSupply = async () => {
        if (typeof ethereum === 'undefined') return;
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const contractAddress = "0x5Cf6e17255B3908D9e3B3dc527a18c80179fc871";
        const contract = new ethers.Contract(contractAddress, catShibApi, provider);
        const maxSupply = await contract.MAX_SUPPLY();
        setMaxSupply(Number(formatUnits(maxSupply, 0)))
    }

    const getTotalSupply = async () => {
        if (typeof ethereum === 'undefined') return;
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const contractAddress = "0x5Cf6e17255B3908D9e3B3dc527a18c80179fc871";
        const contract = new ethers.Contract(contractAddress, catShibApi, provider);
        const totalSupply = await contract.totalSupply();
        const formatTotalSupply = Number(formatUnits(totalSupply, 0));
        setTotalSupply(formatTotalSupply);
        await getFeeMint(formatTotalSupply)
    }

    const mintNFT = async () => {
        if (typeof ethereum === 'undefined' || count <= 0) return;
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()
        const contractAddress = "0x5Cf6e17255B3908D9e3B3dc527a18c80179fc871";
        const contract = new ethers.Contract(contractAddress, catShibApi, signer);
        const tx = await contract.mintArt(count, {value: ethers.utils.parseEther(currentFeeMint.toString())});
        await getData();
        // check status
    }

    return (
        <>
            <MintModalStyleWrapper className="modal_overlay">
                <div className="mint_modal_box">
                    <div className="mint_modal_content">
                        <div className="modal_header">
                            <h2>Collect YOUR NFT before end</h2>
                            <button onClick={() => mintModalHandle()}>
                                <FiX/>
                            </button>
                        </div>
                        <div className="modal_body text-center">
                            <div className="mint_img">
                                <img src={mintImg} alt="catshib nft mint"/>
                            </div>
                            <div className="mint_count_list">
                                <ul>
                                    <li>
                                        <h5>Remaining</h5>
                                        <h5>
                                            {totalSupply}/<span>{maxSupply}</span>
                                        </h5>
                                    </li>
                                    <li>
                                        <h5>Price</h5>
                                        <h5>{currentFeeMint} ETH</h5>
                                    </li>
                                    <li>
                                        <h5>Quantity</h5>
                                        <div className="mint_quantity_sect">
                                            <button
                                                onClick={() => {
                                                    if (count > 1) {
                                                        setCount(count - 1);
                                                    }
                                                }}
                                            >
                                                -
                                            </button>
                                            <input
                                                type="text"
                                                id="quantity"
                                                value={count}
                                                onChange={(e) => {
                                                    if (e.target.value > 0 && e.target.value <= 5 - totalNFT) {
                                                        setCount(e.target.value);
                                                    }
                                                }}
                                            />
                                            <button onClick={() => {
                                                if (count < 5 - totalNFT) {
                                                    setCount(count + 1)
                                                }
                                            }}>+
                                            </button>
                                        </div>
                                        <h5>
                                            <span>1</span> NFT
                                        </h5>
                                    </li>
                                </ul>
                            </div>
                            <div className="modal_mint_btn">
                                <Button lg variant="mint" onClick={mintNFT}>
                                    Mint Now
                                </Button>
                            </div>
                        </div>

                        <div className="modal_bottom_shape_wrap">
              <span className="modal_bottom_shape shape_left">
                <img src={hoverShape} alt="catshib nft hover shape"/>
              </span>
                            <span className="modal_bottom_shape shape_right">
                <img src={hoverShape} alt="catshib nft hover shape"/>
              </span>
                        </div>
                    </div>
                </div>
            </MintModalStyleWrapper>
        </>
    );
};

export default MintNowModal;
