import checkIcon from "../../images/icon/roadmap_check.svg"
import spinnerIcon from "../../images/icon/roadmap_spiner.png"

const data = [
    {
        icon: checkIcon,
        title: "Phase 01",
        subtitle: "Realease Website",
        text: "New logo and website release! Check out our fresh look and user-friendly features. Discover our services and stay up to date."
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 02",
        subtitle: "Marketing Campaign",
        text: "Run ads campaign on twitter and community call"
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 03",
        subtitle: "Fair Launch",
        text: "Fair-launch on launchpad Shibarium platform"
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 04",
        subtitle: "Dex Exchange",
        text: "Fast track on CGC, CMC and Dex tier 1 exchange"
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 05",
        subtitle: "Minting NFTs", 
        text: "Use CatShib token to mint CatShib NFT collection"
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 06",
        subtitle: "NFT staking", 
        text: "New D-app release: stake CatShib NFTs and earn CatShib tokens! Discover the rewards and join the community of cat lovers"
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 06",
        subtitle: "Metaverse Ready", 
        text: "Immerse yourself in a virtual world full of cat-themed adventures, games, and collectibles. Join the army, collect CatShib NFTs, and explore the possibilities of our metaverse"
    }, 
]

export default data;