const data = [
  {
    title: "What is CatShibarium ?",
    text: "CatShib Token is an essential administrative token on the Catshibarium platform, serving as a means of payment for staking NFTs and minting NFT cats on the Shibarium meme platform.",
  },
  {
    title: "How we can buy and invest NFT ?",
    text: "To be able to mint CatShibarium NFT's you must use CatShib Token to join Mint, Mint price will be announced later, Each whitelisted address can only mint 1 NFT",
  },
  {
    title: "Why we should choose Mint NFT ?",
    text: "CatShibarium NFT's is widely used, please see our roadmap",
  },
  {
    title: "When is the Fair-Launch?",
    text: "As soon as the launchpad platform at Shibarium is released",
  },
  {
    title: "CatShib token price ?",
    text: "Because we have chosen the fair launch method for selling, the token price will be announced after the fair launch",
  },
  {
    title: "What is CatShib token contract address ?",
    text: "Contract address will be announced when fairlaunch takes place.",
  },
];

export default data;
