import {useEffect, useState} from "react";
import { useModal } from "../../../../utils/ModalContext";
import { FaDiscord, FaTwitter, FaWallet } from "react-icons/fa";
import { BsXLg } from "react-icons/bs";
import Button from "../../../../common/button";
import logo from "../../../../assets/images/logo.png";
import openseaIcon from "../../../../assets/images/icon/opensea.svg";

import MobileMenuStyleWrapper from "./MobileMenu.style";
import {isAccountConnected} from "../../../../lib/metamaskhandler";

const MobileMenu = ({ mobileMenuhandle }) => {
  const { walletModalHandle } = useModal();
  const [isSubmenu, setSubmenu] = useState(false);
  const [isConnected, setIsConnect] = useState(null)

  useEffect(() => {
    isAccountConnected().then(accounts => {
      if (accounts.length) {
        setIsConnect(accounts[0])
        console.log(accounts[0])
      }
    })
  }, [])

  const handleSubmenu = () => {
    setSubmenu(!isSubmenu);
  };

  const minimizeAddress = (address) => {
    console.log(address)
    if (address.length < 8) {
      return address
    }
    const start = address.slice(0, 4);
    const end = address.slice(-4);
    return `${start}...${end}`
  }

  return (
    <MobileMenuStyleWrapper className="catshib_mobile_menu">
      <div className="catshib_mobile_menu_content">
        <div className="mobile_menu_logo">
          <img className="catshib_logo" src={logo} alt="catshib logo" />
          <button
            className="mobile_menu_close_btn"
            onClick={() => mobileMenuhandle()}
          >
            {" "}
            <BsXLg />{" "}
          </button>
        </div>
        <div className="catshib_mobile_menu_list">
          <ul>
            <li>
              <a href="#home">Home</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#roadmap">Roadmap</a>
            </li>
            <li>
              <a href="#team">Team</a>
            </li>
            <li>
              <a href="#faq">FAQ</a>
            </li>
          </ul>
        </div>
        <div className="mobile_menu_social_links">
          <a href="# ">
            <img src={openseaIcon} alt="catshib social icon" />
          </a>
          <a href="# ">
            <FaTwitter />
          </a>
          <a href="# ">
            <FaDiscord />
          </a>
        </div>
        <Button
            sm
            variant="hovered"
            className="connect_btn"
            onClick={() => {
              if (!isConnected) walletModalHandle();
            }}
        >
          <FaWallet /> {isConnected ? minimizeAddress(isConnected) : "Connect"}
        </Button>
      </div>
    </MobileMenuStyleWrapper>
  );
};

export default MobileMenu;
